import React from 'react'
import { BuilderBlocks, BuilderElement } from '@builder.io/react'
import { styled } from '../../../../stitches.config'

export type BuilderBlockType = BuilderElement

interface ActionCardProps {
    showImage: boolean
    showTitleEyebrow: boolean
    showSubtitle: boolean
    showButton: boolean
    imageComponent: React.ReactNode | { content: BuilderBlockType[] }
    headlineEyebrowComponent: React.ReactNode | { content: BuilderBlockType[] }
    multicolorTextComponent: React.ReactNode | { content: BuilderBlockType[] }
    subtitleTextComponent: React.ReactNode | { content: BuilderBlockType[] }
    linkButtonComponent: React.ReactNode | { content: BuilderBlockType[] }
    builderBlock: BuilderBlockType
}

const Flex = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '$lg',
    flex: '1',
})

export const ActionCard = ({
    showImage,
    showTitleEyebrow,
    showSubtitle,
    showButton,
    imageComponent,
    headlineEyebrowComponent,
    multicolorTextComponent,
    subtitleTextComponent,
    linkButtonComponent,
    builderBlock,
}: ActionCardProps) => {
    return (
        <Flex>
            {showImage && (
                <BuilderBlocks
                    parentElementId={builderBlock.id}
                    dataPath={'component.options.imageComponent'}
                    blocks={(imageComponent as any).content || imageComponent}
                />
            )}
            {showTitleEyebrow && (
                <BuilderBlocks
                    parentElementId={builderBlock.id}
                    dataPath={'component.options.headlineEyebrowComponent'}
                    blocks={
                        (headlineEyebrowComponent as any).content ||
                        headlineEyebrowComponent
                    }
                />
            )}
            <BuilderBlocks
                parentElementId={builderBlock.id}
                dataPath={'component.options.multicolorTextComponent'}
                blocks={
                    (multicolorTextComponent as any).content ||
                    multicolorTextComponent
                }
                style={{ flexGrow: 1 }}
            />
            {showSubtitle && (
                <BuilderBlocks
                    parentElementId={builderBlock.id}
                    dataPath={'component.options.subtitleTextComponent'}
                    blocks={
                        (subtitleTextComponent as any).content ||
                        subtitleTextComponent
                    }
                    style={{ flexGrow: 1 }}
                />
            )}
            {showButton && (
                <BuilderBlocks
                    parentElementId={builderBlock.id}
                    dataPath={'component.options.linkButtonComponent'}
                    blocks={
                        (linkButtonComponent as any).content ||
                        linkButtonComponent
                    }
                />
            )}
        </Flex>
    )
}

export default ActionCard
